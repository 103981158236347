import {useEffect, useState} from "react"

export const usePlanetDetails = (isOpen, setIsOpen, data) => {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        if(isOpen){

            setIsLoading(false)

        }

        // eslint-disable-next-line
    }, [data])

    const handleClose = () => {

        setIsOpen(false)
        setIsLoading(true)

    }

    return{
        isLoading,
        handleClose
    }

}