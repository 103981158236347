import styled from "styled-components"

export const Container = styled.section`
  
  height: 100%;
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 45% 55%;

`