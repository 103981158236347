import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Router from "./pages/Router"

const root = ReactDOM.createRoot(document.getElementById('root'))

//all app pages are provided by the router
root.render(
    <React.StrictMode>
        <Router/>
    </React.StrictMode>
)