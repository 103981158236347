import styled from "styled-components"

export const DataContainer = styled.div`
  
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-rows: 30% 70%;
  align-items: center;

`