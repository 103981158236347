import {useEffect, useState} from "react"
import {apiCall} from "../../lib/utils/apiCall"
import {apiQueries} from "../../lib/constants/apiQueries"

import PlanetCard from "../../components/planetCards/PlanetCard"
import {Text} from "../../styles/shared/Text"

const usePlanetCards = ()  => {

    const [planetCards, setPlanetCards] = useState([])

    //this will execute only on mounted for get initial planet cards
    useEffect(() => {

        getPlanetCards()

        // eslint-disable-next-line
    }, [])

    const getPlanetCards = () => {

        //poor implementation of loading state
        const loadingJSX = []

        for (let i = 0; i < 12; i++) {
            loadingJSX.push(<Text key={i}>Loading ...</Text>)
        }

        setPlanetCards(loadingJSX)

        //call function to get data from api
        apiCall(apiQueries.GET_2022_PLANET_NAMES)
        .then( response => {

            //API don`t have a limit option so the smallest response has 700+ registers but just need
            // 12 for cards for that reason im discriminating randomly and saving only 12
            const randomPlanets = []

            //This new array is because different objects from api are the same but with minimal
            //differences for that reason need discriminate by name
            const alreadyPlanetNames = []

            for (let i = 0; i < 12; i++) {

                const randomPlanetNumber = Math.floor(Math.random() * response.length)
                const randomPlanet = response.slice((randomPlanetNumber - 1), randomPlanetNumber)
                const planetName = randomPlanet[0].pl_name

                if(!alreadyPlanetNames.includes(planetName)){

                    randomPlanets.push(randomPlanet)
                    alreadyPlanetNames.push(planetName)

                }else{
                    i--
                }

            }

            //for every planet create a PlanetCard
            const jsxPlanets = randomPlanets.map( planet => {

                const planetCards = []
                const {
                    pl_name
                } = planet[0]

                planetCards.push(

                    <PlanetCard planetName={pl_name} key={pl_name}/>

                )

                return planetCards

            })

            setPlanetCards(jsxPlanets)

        })
        .catch(e => {

            //just handling error with console log for now
            console.error(e)

        })

    }

    return{
        planetCards
    }

}

export default usePlanetCards