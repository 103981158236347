export const apiCall = async (query)  => {

    const PROXY_SERVER = 'http://localhost:4000/proxy/' //only in development environment

    //need a proxy server on dev because the api server don't have CORS configuration
    if(process.env.NODE_ENV === 'development'){

        console.log('dev')

        return fetch(PROXY_SERVER, {

            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({url: query})

        }).then(response => response.json())

    }else{

        console.log('prod')

        return fetch(query, {

            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }

        }).then(response => response.json())

    }

}