import styled from "styled-components"
import {standardFontSize} from "../../assets/values/fontSizes/standardFontSize"

export const Title = styled.h2`
    
    font-size: ${standardFontSize.title};
    color: ${({theme}) => theme.colors.title};
    z-index: 1;
    margin: 0;
    
`