import styled from "styled-components"

export const CardContainer = styled.div`
  
  height: 80%;
  width: 80%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: 75% 25%;

`