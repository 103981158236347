import {Fragment} from "react"

import {errorPageStrings} from "../../assets/strings/errorPage/errorPageStrings"
import {NotFoundPageStyled} from "../../styles/pages/notFoundPage"
import {Text} from "../../styles/shared/Text"
import {Title} from "../../styles/shared/Title"

const MainPage = () => {

    return(

        <Fragment>

            <NotFoundPageStyled.Container id='NotFoundPageContainer'>

                <Title id='NotFoundPageTitle'>{errorPageStrings.title}</Title>
                <Text id='NotFoundPageText'>{errorPageStrings.text}</Text>

            </NotFoundPageStyled.Container>

        </Fragment>

    )

}

export default MainPage