import {createContext, useState} from "react"

export const PlanetDetailsContext = createContext(null)

const PlanetDetailsProvider = ({children}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState({})

    const value = {
        isOpen,
        setIsOpen,
        data,
        setData
    }

    return(

        <PlanetDetailsContext.Provider value={value}>
            {children}
        </PlanetDetailsContext.Provider>

    )

}

export default PlanetDetailsProvider