import styled from "styled-components"
import closeButton from "../../../assets/images/planetDetails/closeButton.svg"

export const CloseButton = styled.button`

  background-image: url(${closeButton});
  position: absolute;
  height: 50px;
  width: 50px;
  border: 0;
  padding: 0;
  top: 10px;
  right: 10px;
  background-color: transparent;

`