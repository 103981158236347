import styled from "styled-components"
import mainPageBackground from "../../../assets/images/mainPage/background.jpg"

export const Background = styled.div`
  
  height: 100%;
  width: 100%;
  position: fixed;
  background-image: url(${mainPageBackground});
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.6);
  z-index: 0; //index 0 just for background

`