import {useContext, useRef} from "react"
import {PlanetDetailsContext} from "../../providers/PlanetDetailsProvider"
import {apiQueries} from "../../lib/constants/apiQueries";
import {apiCall} from "../../lib/utils/apiCall";

const usePlanetCard = () => {

    const svgObject = useRef(null)

    const {
        setIsOpen,
        setData
    } = useContext(PlanetDetailsContext)

    const handleClick = (e) => {

        const {id} = e.currentTarget
        const planetName = id.split('&')[1]
        const svg = svgObject.current

        //open PlanetDetails component and call function to get data from api
        setIsOpen(true)

        const query = `${apiQueries.GET_PLANET_DETAILS}'${planetName}'&format=json`

        apiCall(query).then(response => {

            const {
                disc_year,
                discoverymethod,
                hostname,
                pl_masse,
                pl_name,
                pl_orbper,
                pl_rade,
                st_teff,
                sy_dist,
                sy_mnum,
                sy_pnum,
                sy_snum,
                tic_id,
            } = response[0]

            setData({

                name: pl_name,
                mass: pl_masse,
                orbitalPeriod: pl_orbper,
                radius: pl_rade,
                discoverYear: disc_year,
                discoverMethod: discoverymethod,
                stellarTemperature: st_teff,
                stellarName: hostname,
                systemDistance: sy_dist,
                planetaryMoonsNumber: sy_mnum,
                planetaryPlanetsNumber: sy_pnum,
                planetaryStarsNumber: sy_snum,
                tessId: tic_id,
                svg: svg

            })

        })
        .catch(e => {

            //just handling error with console log for now
            console.error(e)

        })


    }

    return{
        svgObject,
        handleClick
    }

}

export default usePlanetCard