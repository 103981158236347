import styled from "styled-components"

export const CardsContainer = styled.section`
  
  height: 70%;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(3, 33.33%);
  justify-items: center;
  align-items: center;
  z-index: 1;

`