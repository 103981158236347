import {Fragment} from "react"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import {ThemeProvider} from "styled-components"

import MainPage from "./mainPage/MainPage"
import NotFoundPage from "./notFoundPage/NotFoundPage"

import {colorThemes} from "../assets/values/colorThemes/colorThemes"
import {RouterStyled} from "../styles/pages/router"

const Router = () => {

    const router = createBrowserRouter([
        {
            path: '/',
            element: <MainPage/>,
            errorElement: <NotFoundPage/>
        }
    ])

    return (

        <Fragment>

            {/*hardcoded theme to dark until more themes are added on colorThemes*/}
            <ThemeProvider theme={colorThemes.dark}>

                <RouterStyled.Container id='RouterContainer'>

                    <RouterProvider router={router}/>

                </RouterStyled.Container>

            </ThemeProvider>

        </Fragment>

    )

}

export default Router