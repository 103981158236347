import {Container} from "./Container"
import {Card} from "./Card"
import {DataContainer} from "./DataContainer"
import {DataTitles} from "./DataTitles"
import {DataBody} from "./DataBody"
import {DataFields} from "./DataFields"
import {CloseButton} from "./CloseButton"

export const PlanetDetailsStyled = {
    PlanetDetailsContainer: Container,
    PlanetDetailsCard: Card,
    DataContainer: DataContainer,
    DataTitles: DataTitles,
    DataBody: DataBody,
    DataFields: DataFields,
    CloseButton: CloseButton
}