import {Fragment} from "react"
import usePlanetCards from "../../hooks/planetCards/usePlanetCards"
import {PlanetCardsStyled} from "../../styles/components/planetCards"

const PlanetCards = () => {

    const{
        planetCards
    } = usePlanetCards()

    return(

        <Fragment>

            <PlanetCardsStyled.CardsContainer id='planetCardsContainer'>
                {planetCards}
            </PlanetCardsStyled.CardsContainer>

        </Fragment>

    )

}

export default PlanetCards