export const planetDetailStrings = {

    mass: 'Amount of matter contained in the planet, measured in units of masses of the Earth',
    orbitalPeriod: 'Orbital Period (Days)',
    radius: 'Length of a line from the center of the planet to its surface, measured in units of radius of the Earth',
    discoverYear: 'Year the planet was discovered',
    discoverMethod: 'Method by which the planet was first identified',
    stellarTemperature: 'Temperature of the star as modeled by a black body emitting the same total amount of electromagnetic radiation (Kelvin)',
    stellarName: 'Stellar name most commonly used in the literature',
    systemDistance: 'Distance to the planetary system in units of parsecs',
    planetaryMoonsNumber: 'Number of moons in the planetary system',
    planetaryPlanetsNumber: 'Number of planets in the planetary system',
    planetaryStarsNumber: 'Number of stars in the planetary system',

}