import {Fragment} from "react"

const PlanetCardSVG = ({id, svgObject}) => {

    const newId = id.replace(/\s+/g, '')

    const colors = [
        ['rgb(71, 0, 179)', 'rgb(31, 0, 77)'],
        ['rgb(128,0,179)', 'rgb(110,19,57)'],
        ['rgb(20,100,107)', 'rgb(15,80,110)'],
        ['rgb(164,116,28)', 'rgb(98,68,57)'],
        ['rgb(201,118,37)', 'rgb(138,59,6)'],
        ['rgb(80,114,49)', 'rgb(46,56,41)'],
        ['rgb(103,8,43)', 'rgb(45,9,24)'],
        ['rgb(31,112,47)', 'rgb(17,50,70)'],
        ['rgb(114,114,114)', 'rgb(45,44,49)'],
        ['rgb(56,56,56)', 'rgb(107,57,21)'],
        ['rgb(84,128,47)', 'rgb(18,70,42)'],
        ['rgb(26,112,103)', 'rgb(32,44,19)'],
        ['rgb(60,86,49)', 'rgb(105,138,84)'],
        ['rgb(121,27,121)', 'rgb(155,12,155)'],
        ['rgb(166,128,23)', 'rgb(68,27,15)'],
        ['rgb(13,13,126)', 'rgb(88,35,115)'],
        ['rgb(112,15,15)', 'rgb(108,53,17)'],
        ['rgb(129,21,155)', 'rgb(82,6,82)'],
        ['rgb(139,138,159)', 'rgb(87,87,87)'],
        ['rgb(101,19,161)', 'rgb(31,76,80)']
    ]

    const name = id.split('&')[1]

    //random colors for each planet
    const randomColor = Math.floor(Math.random() * 19) //
    const color1 = colors[randomColor][0]
    const color2 = colors[randomColor][1]

    //random gradients for each planet
    const x1 = `${Math.floor(Math.random() * 100)}%`
    const x2 = `${Math.floor(Math.random() * 100)}%`
    const y1 = `${Math.floor(Math.random() * 100)}%`
    const y2 = `${Math.floor(Math.random() * 100)}%`

    //this is for planetDetails or detailed view
    svgObject.current = (

        <svg id={`svg-id-${name}`} height="400" width="400" xmlns="http://www.w3.org/2000/svg">

            <defs>
                <linearGradient id={`grad-id-${newId}`} x1={x1} y1={y1} x2={x2} y2={y2}>
                    <stop offset="0%" stopColor={color1} stopOpacity='1'/>
                    <stop offset="100%" stopColor={color2} stopOpacity='1'/>
                </linearGradient>
            </defs>

            <circle cx="200" cy="200" r="190" strokeWidth="3" fill={`url(#grad-id-${newId})`} />

        </svg>

    )

    return(

        <Fragment>

            <svg id={`svg-id-${name}`} height="100" width="100" xmlns="http://www.w3.org/2000/svg">

                <defs>
                    <linearGradient id={`grad-id-${newId}`} x1={x1} y1={y1} x2={x2} y2={y2}>
                        <stop offset="0%" stopColor={color1} stopOpacity='1'/>
                        <stop offset="100%" stopColor={color2} stopOpacity='1'/>
                    </linearGradient>
                </defs>

                <circle cx="50" cy="50" r="40" strokeWidth="3" fill={`url(#grad-id-${newId})`} />

            </svg>

        </Fragment>

    )

}

export default PlanetCardSVG