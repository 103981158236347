import styled from "styled-components"

export const Container = styled.section`
  
  height: 100vh;
  width: 100%;
  display: ${props => props.$isOpen ? 'flex': 'none'};
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 2;
  backdrop-filter: blur(100px);

`