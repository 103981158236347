import {Fragment, useContext} from "react"
import {usePlanetDetails} from "../../hooks/planetDetails/usePlanetDetails"

import {PlanetDetailsStyled} from "../../styles/components/planetDetails"
import {PlanetDetailsContext} from "../../providers/PlanetDetailsProvider"
import {Text} from "../../styles/shared/Text"
import {Title} from "../../styles/shared/Title"
import {SubTitle} from "../../styles/shared/SubTitle"
import {planetDetailStrings} from "../../assets/strings/planetDetails/planetDetailStrings";

const PlanetDetails = () => {

    //getting data from context
    const {
        isOpen,
        setIsOpen,
        data
    } = useContext(PlanetDetailsContext)

    const{
        isLoading,
        handleClose
    } = usePlanetDetails(isOpen, setIsOpen, data)

    const detailedView = (

        <PlanetDetailsStyled.DataContainer>

            <PlanetDetailsStyled.DataTitles>
                <Title>{data.name}</Title>
                <SubTitle>{data.tessId}</SubTitle>
            </PlanetDetailsStyled.DataTitles>

            <PlanetDetailsStyled.DataBody>

                {data.svg}

                <PlanetDetailsStyled.DataFields>

                    <Text>{`${planetDetailStrings.orbitalPeriod}: ${data.orbitalPeriod}`}</Text>
                    <Text>{`${planetDetailStrings.radius}: ${data.radius}`}</Text>
                    <Text>{`${planetDetailStrings.mass}: ${data.mass}`}</Text>
                    <Text>{`${planetDetailStrings.discoverYear}: ${data.discoverYear}`}</Text>
                    <Text>{`${planetDetailStrings.discoverMethod}: ${data.discoverMethod}`}</Text>
                    <Text>{`${planetDetailStrings.stellarName}: ${data.stellarName}`}</Text>
                    <Text>{`${planetDetailStrings.systemDistance}: ${data.systemDistance}`}</Text>
                    <Text>{`${planetDetailStrings.planetaryMoonsNumber}: ${data.planetaryMoonsNumber}`}</Text>
                    <Text>{`${planetDetailStrings.planetaryPlanetsNumber}: ${data.planetaryPlanetsNumber}`}</Text>
                    <Text>{`${planetDetailStrings.planetaryStarsNumber}: ${data.planetaryStarsNumber}`}</Text>

                </PlanetDetailsStyled.DataFields>

                <PlanetDetailsStyled.CloseButton onClick={handleClose}/>

            </PlanetDetailsStyled.DataBody>

        </PlanetDetailsStyled.DataContainer>

    )

    const setView = () => {
        if(isLoading) return <Text>Loading ...</Text>
        else return detailedView
    }

    return (

        <Fragment>

            <PlanetDetailsStyled.PlanetDetailsContainer id='PlanetDetailsContainer'
            $isOpen={isOpen}>

                <PlanetDetailsStyled.PlanetDetailsCard>

                    {setView()}

                </PlanetDetailsStyled.PlanetDetailsCard>

            </PlanetDetailsStyled.PlanetDetailsContainer>

        </Fragment>

    )

}

export default PlanetDetails