import styled from "styled-components"

export const DataFields = styled.div`
  
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

`