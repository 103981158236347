import styled from "styled-components"

export const DataBody = styled.div`
  
  height: 90%;
  width: 90%;
  display: grid;
  grid-template-columns: 45% 55%;
  justify-content: center;
  align-items: center;
  justify-self: center;

`