import {Fragment} from "react"

import {MainPageStyled} from "../../styles/pages/mainPage"
import {Title} from "../../styles/shared/Title"
import {SubTitle} from "../../styles/shared/SubTitle"
import {mainPageStrings} from "../../assets/strings/mainPage/mainPageStrings"

import PlanetDetailsProvider from "../../providers/PlanetDetailsProvider"
import PlanetCards from "../../components/planetCards/PlanetCards"
import PlanetDetails from "../../components/planetDetails/PlanetDetails"

const MainPage = () => {

    return(

        <Fragment>

            <MainPageStyled.Container id='MainPageContainer'>

                <PlanetDetailsProvider>

                    <PlanetDetails/>

                    {/*styled component just for background position fixed z-index 0*/}
                    <MainPageStyled.Background id='MainPageBackground'/>

                    <MainPageStyled.TitlesContainer id='MainPageTitlesContainer'>

                        <Title id='MainPageTitle'>{mainPageStrings.title}</Title>
                        <SubTitle id='MainPageSubTitle'>{mainPageStrings.subTitle}</SubTitle>

                    </MainPageStyled.TitlesContainer>

                    <PlanetCards/>

                </PlanetDetailsProvider>

            </MainPageStyled.Container>

        </Fragment>

    )

}

export default MainPage