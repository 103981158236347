import styled from "styled-components"

export const DataTitles = styled.div`
  
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`