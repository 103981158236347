const BASE_URL = 'https://exoplanetarchive.ipac.caltech.edu/TAP/sync?query='
const ENCODE = '&format=json'

const GET_2022_PLANET_NAMES = `${BASE_URL}select+pl_name+from+ps+where+disc_year>2022${ENCODE}`
const GET_PLANET_DETAILS = `${BASE_URL}select+pl_name,hostname,sy_snum,sy_pnum,sy_mnum,discoverymethod,
disc_facility,pl_refname,pl_orbper,pl_rade,disc_year,pl_masse,st_teff,st_mass,st_rad,sy_dist,tic_id+from+ps+
where+pl_name+=+`

export const apiQueries  = {
    GET_2022_PLANET_NAMES: GET_2022_PLANET_NAMES,
    GET_PLANET_DETAILS: GET_PLANET_DETAILS
}