import {Fragment} from "react"
import usePlanetCard from "../../hooks/planetCards/usePlanetCard"

import PlanetCardSVG from "./PlanetCardSVG"

import {Text} from "../../styles/shared/Text"
import {PlanetCardsStyled} from "../../styles/components/planetCards"

const PlanetCard = ({planetName}) => {

    const{
        svgObject,
        handleClick
    } = usePlanetCard()

    return(

        <Fragment>

            <PlanetCardsStyled.CardContainer id={`planetCardContainer&${planetName}`} onClick={handleClick}>

                <PlanetCardSVG id={`planetCardSVG&${planetName}`} svgObject={svgObject}/>
                <Text id={`planetCardText&${planetName}`}>{planetName}</Text>

            </PlanetCardsStyled.CardContainer>

        </Fragment>

    )

}

export default PlanetCard