import styled, { keyframes } from "styled-components"

const crescendo = keyframes`

  0%   {transform: scale(.1);}
  100% {transform: scale(1);}
    
`

export const Card = styled.div`
  
  min-width: 950px;
  height: 80%;
  width: 60%;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  border-radius: 10px;
  animation: ${crescendo} .3s alternate;

`