import styled from "styled-components"
import {standardFontSize} from "../../assets/values/fontSizes/standardFontSize"

export const Text = styled.p`
  
  font-size: ${standardFontSize.text};
  color: ${({theme}) => theme.colors.text};
  text-align: center;
  margin: 7px 0;

`